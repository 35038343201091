import { ClerkApp } from "@clerk/remix";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { ConfigProvider, theme } from "antd";
import {
  ExternalScripts,
  type ExternalScriptsHandle,
} from "remix-utils/external-scripts";
import logo from "./assets/compact-logo.png";

import getServerConfig from "server/config";

import secondcropLogo from "./assets/compact-logo.png";
import styles from "./styles/tailwind.css?url";
import "./styles/clerkOverrides.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import type { LinksFunction, LoaderFunction } from "@remix-run/node";
import { useEffect, useState } from "react";
import tailwindConfig from "tailwind.config";
import { useDarkMode } from "./hooks/useDarkMode";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

function App() {
  const data = useLoaderData<typeof loader>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const checkReadyState = () => {
      if (document.readyState === "complete") {
        setIsLoading(false);
      }
    };
    if (document.readyState === "complete") {
      setIsLoading(false);
    } else {
      window.addEventListener("load", checkReadyState);
    }
    return () => window.removeEventListener("load", checkReadyState);
  }, []);

  const darkMode = useDarkMode();

  return (
    <html lang="en" className="h-full bg-white font-main">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {isLoading && (
          <div className="loading-screen fixed inset-0 bg-white z-50 flex items-center justify-center ">
            <img
              src={secondcropLogo}
              alt="SecondCrop"
              className="w-auto h-12 "
            />
          </div>
        )}
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: tailwindConfig.theme.extend.colors.loamGreen,
            },
          }}
        >
          <Outlet />
        </ConfigProvider>
        <script
          // biome-ignore lint/security/noDangerouslySetInnerHtml: Migrate to biome
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <ScrollRestoration />
        <ExternalScripts />
        <Scripts />
        <script
          defer
          src="https://cloud.umami.is/script.js"
          data-website-id="74ecde7c-0811-477f-b0c6-1b3881aaa963"
        />
      </body>
    </html>
  );
}

export const loader: LoaderFunction = (args) => {
  return rootAuthLoader(args, () => {
    const serverConfig = getServerConfig();
    return json({
      ENV: {
        ARCGIS_CLIENT_API_KEY: serverConfig.ARCGIS_CLIENT_API_KEY,
        GIT_COMMIT_HASH: serverConfig.GIT_COMMIT_HASH,
        NODE_ENV: process.env.NODE_ENV,
        PANDADOC_CONTRACT_URL: serverConfig.PANDADOC_CONTRACT_URL,
        PANDADOC_EVENT_ENV: serverConfig.PANDADOC_EVENT_ENV,
        SECONDCROP_FIELD_MAP_ATTACHMENTS_MAX:
          serverConfig.SECONDCROP_FIELD_MAP_ATTACHMENTS_MAX,
        MAPBOX_PUBLIC_KEY: serverConfig.MAPBOX_PUBLIC_KEY,
      },
    });
  });
};

export const handle: ExternalScriptsHandle = {
  scripts: () => {
    return [
      {
        src: "https://cloud.umami.is/script.js",
        "data-website-id": "74ecde7c-0811-477f-b0c6-1b3881aaa963",
      },
    ];
  },
};

export function ErrorBoundary() {
  const error = useRouteError();
  const isDevMode = process.env.NODE_ENV === "development";

  return (
    <html lang="en">
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <main
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img
            src={logo}
            alt="SecondCrop"
            style={{
              width: "200px",
              margin: "1rem",
            }}
          />
          <h1
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {isRouteErrorResponse(error)
              ? `${error.status} ${error.statusText}`
              : "Oops! Something went wrong."}
          </h1>
          {!isRouteErrorResponse(error) && (
            <p>{error instanceof Error ? error.message : "Unknown Error"}</p>
          )}
          <a
            href="/"
            style={{
              display: "inline-block",
              padding: "0.5rem 1rem",
              backgroundColor: "#4FAF3D",
              color: "white",
              textDecoration: "none",
              borderRadius: "4px",
              margin: "1rem",
            }}
          >
            Go back home
          </a>
          {isDevMode && error instanceof Error && (
            <pre
              style={{
                padding: "1rem",
                backgroundColor: "#ffeeee",
                overflowX: "auto",
                fontSize: "0.75rem",
                width: "70%",
              }}
            >
              {error.stack}
            </pre>
          )}
        </main>
        <Scripts />
      </body>
    </html>
  );
}

export default ClerkApp(App);
